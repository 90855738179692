.public-page {
	// background-color: #F5F5F5;
	height: 100%;
}

.private-page {
	// padding-bottom: 64px;
}

.breadcrumb__container {
	color: #000;
	margin: 0;
	padding: 10px 20px;
	border-radius: 0;

	span {
		display: inline-block;
		padding: 5px;
		font-size: 1.25rem;
		font-weight: 500;
		line-height: 1.6;
		letter-spacing: 0.0075em;
		color: #fff;

		&:first-child {
			padding-left: 0;
		}

		a {
			font-size: 1.25rem;
			font-weight: 500;
			line-height: 1.6;
			letter-spacing: 0.0075em;
			display: block;
			color: #fff;
		}
	}
}

.breadcrumb__separate {
	font-size: 1.5rem !important;
}

.account-info {
	display: flex;
	flex-direction: row;
	padding: 5px;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.6;
	letter-spacing: 0.0075em;
	color: black;
	> .role {
		margin-right: 20px;
		margin-top: 3px;
	}
}

.name-role-info {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	.name {
		font-size: 16px;
	}
	.role {
		font-size: 13px;
	}
}
//SIDE BAR
.sidebar__container {
	// padding-top: 64px;
	.sidebar__container_logo {
		// padding-top: 10px;
		height: 64px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #fff;
		img {
			width: 190px;
		}
		.sidebar__container_desc {
			width: 190px;
		}
		.sidebar__container_title {
			font-size: 20px;
			font-weight: bold;
			margin: 15px 0 0 0;
		}
	}

	.sidebar-menu {
		ul > a {
			border-left: 5px solid $sideBarBgColor;
			.menu-item-icon {
				color: black;
				min-width: 40px !important;
			}
			> svg, span {
				color: black;
			}
		}
		.active {
			border-left: 5px solid $sideBarActiveBgColor;
			background-color: #eeeeee;
		}
	}
}

//CONTAINER
.main__container {
	width: 100%;
	min-height: 100% !important;
	padding: 0 30px 70px 30px;

	// @media (min-width: 540px) and (max-width: 950px) {
	// 	margin-top: 2vw;
	// }
}

.bg-ranking-badge {
	background-image: url('./../images/Landingpg/Ranking/bg_long_page.png');
	background-size: 100vw auto;
	background-repeat: repeat-y;
	background-attachment: local;
}

.footer__container {
	height: $containerFooterHeight;
	width: 100%;
	background-color: $containerFooterBgColor;
	color: $containerFooterColor;
	font-size: 12px;
	font-weight: bold;
	overflow: hidden;
	position: fixed;
	bottom: 0;
	z-index: 100;
	padding-left: 1rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.full-height {
	// height: 100%;
}
.full-width {
	width: 100%;
}
.overflow-x-auto {
	overflow-x: auto;
}


.data-grid-menu {
	display: flex;
	gap: 10px;
}
.data-grid {
	margin-top: 10px;
	min-height: 30rem;
}
.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
    height: 100%;
}

.head-container {
	margin-top: 70px!important;
}







