.page-landing {
  margin:0;
  padding:0;
  outline:0;
}

.page-landing img {
  width:100%;
  max-width: 100%;
}

.page-landing .media-s,
.page-landing .media-m,
.page-landing .media-l {
  display: none;
}

.page-landing .container {
  overflow: hidden !important;
  display: flex;
  justify-content: center;
  position: relative;
}

@media (max-width: 600px) {
  .page-landing .media-s {
    display: block !important;
  }
  .chinese-title{
    color: white;
    font-size: 1.9vw;
  }
}

@media (max-width: 540px) {
  .chinese-title {
    color: white;
    font-size: 12px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .page-landing .media-m {
    display: block !important;
  }
}

@media (max-width: 900px) {
  .page-landing .container a {
    width:100%;
  }
}

@media (min-width: 901px){
  .page-landing .media-l {
    display: block !important;
  }

  .page-landing img.media-l {
    min-width: 150vw !important;
    object-fit: cover;
  } 
}

@media (min-width: 1366px){
  .page-landing img.media-l {
    min-width: 1920px !important;
    object-fit: cover;
  } 
}

.page-landing .container.main {
  z-index: 500;
}

.page-landing .container.date {
  background-color: #A0E0A6;
  margin-top:-200px;
  padding-top:220px;
  padding-bottom: 55px;
  z-index: 400;
}

.page-landing .container.rule {
  z-index: 300;
}

.page-landing .container.prize {
  background-color: #FDD89F;
  margin-top:-100px;
  padding-top:120px;
  padding-bottom: 55px;
  z-index: 200;
}

.page-landing .container.draw {
  background-color: #85DCF2;
  padding-top:40px;
  padding-bottom: 40px;
  z-index: 100;
}

.page-landing .container .btn1 {
  position: absolute;
  bottom:14%;
  cursor: pointer;
}

.page-landing .container .btn2 {
  position: absolute;
  bottom:20%;
  cursor: pointer;
}

.page-landing .container .btn3 {
  position: absolute;
  bottom:28.8%;
  cursor: pointer;
}

.page-landing .container .tnc {
  cursor: pointer;
}

@media (min-width: 601px) and (max-width: 900px) {
  .page-landing .container .btn1 {
    bottom:16%;
  }

  .page-landing .container .btn2 {
    bottom:25%;
  }

  .page-landing .container .btn3 {
    bottom:42%;
  }

  .page-landing .container.date {
    padding-top:220px;
    padding-bottom: 60px;
  }

  .page-landing .container.prize {
    padding-top:120px;
    padding-bottom: 60px;
  }

  .chinese-title {
    font-size: 1.5vw;
    color: white;
    letter-spacing: 2px;
  }
}

@media (min-width: 901px){
  .page-landing .container .btn1 {
    bottom:12%;
  }

  .page-landing .container .btn2 {
    bottom:29%;
  }

  .page-landing .container .btn3 {
    bottom:14%;
    margin-right: -1.8%;
  }

  .page-landing .container.date {
    padding-top:240px;
    padding-bottom: 80px;
  }

  .page-landing .container.prize {
    padding-top:120px;
    padding-bottom: 100px;
  }

  .chinese-title {
    font-size: 15px;
    color: white;
    letter-spacing: 2px;
  }
}

@media (min-width: 1366px){
  .page-landing .container .btn3 {
    bottom:14%;
    margin-right: -20px;
  }

  .chinese-title {
    font-size: 18px;
    color: white;
    letter-spacing: 2px;
  }
}


/* English Version Adjustments */


@media (max-width: 600px) {
  .page-landing.eng .container .btn3 {
    bottom:29.5%;
  }
  
  .english-title {
    color: white;
    font-size: 1.4vw;
  }
}

@media (max-width: 540px) {
  .english-title {
    color: white;
    font-size: 12px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .page-landing.eng .container .btn2 {
    bottom:24.6%;
  }

  .english-title {
    color: white;
    font-size: 1.4vw;
  }
}

@media (min-width: 901px){
  .page-landing.eng .container .btn3 {
    bottom:15.5%;
    margin-right: -1.4%;
  }

  .english-title {
    color: white;
    font-size: 13px;
  }
}

@media (min-width: 1366px){
  .page-landing.eng .container .btn3 {
    bottom:16%;
    margin-right: -14px;
  }
  
  .english-title {
    color: white;
    font-size: 15px;
  }
}

.full-width-banner {
  width: calc(100% + 60px);
  padding: 1.5rem;

  @media (max-width: 540px) {
    width: calc(100% + 20px);
  }
}

.how-to-play-video {
  position: relative;
  aspect-ratio: 16/9;
}