.landing-after-login {
  box-sizing: border-box ;
  width: 100%;
  height: auto;
  background-image: url('../../images/Landingpg/Registration-period/Background 1.png');
  background-size: 100vw auto;
  background-repeat: no-repeat;
  background-attachment: local;
  // padding-top: 9%;
  padding-top: 70px;
  margin: 0;
  line-height: 1.5;
}

// .english-title {
//   color: white;
//   // font-size: 1vw;
//   // font-family: jf-openhuninn;
// }

// .chinese-title {
//   font-size: 1vw;
//   color: white;
//   letter-spacing: 2px;
// }


.menu-item {
  font-size: 2.5rem!important;
  color: white;
}

.MuiListItemText-root {
  span {
    // font-family: NotoSansCJKtc-Bold;
  }
}

.fontENG {
  span {
    font-family: "Arial Bold", Arial, sans-serif;
    font-weight: bold;
  }
}

.view-more-img {
  margin-right: 0.5vw;
  width: 36px;
  height: 26px;
}

.view-more {
  font-size: 1.3vw;
}

.personalMPF {
  font-size: 1.2vw;
  margin-bottom: 1.5rem;
}

.investOverview {
  font-size: 1vw;
  padding-bottom: 0.5vw;
}

.dateTitle {
  font-size: 1vw;
  padding-bottom: 0.5vw;
  color: #B7B7B7;
}

.manage-button {
  width: 5vw;
  height: 2.2vw;
}

.sub-title {
  font-size: 1.2vw;
}

.height-title {
  height: 120px;
  cursor: pointer;
}

.sub-content {
  width: 100%;
  margin-right: 5px;
  padding-bottom: 1.5rem;
  font-size: 1.2rem;
  border: 1px solid #e0e0e0;
  border-width: 0 0 1px 0;
}

.other-fund {
  padding: 25px 0;
}

.text-danger {
  color: red;
}

.percentage {
  width: 15px;
  height: 15px;
  background-color: green;
  margin-right: 10px;
}

.core {
  background-color: #00FFFF;
}

.percentage-2 {
  background-color: #0000c0;
}

.percentage-3 {
  background-color: #ff7769;
}

.recordTitle1 {
  text-align: center;
  font-weight: bold;
  border: 1px solid green;
  border-width: 0 0 1px 0;
  margin-bottom: 0.5rem;
}

.core-content {
  border: 1px solid #00FFFF;
  border-width: 0 0 1px 0;
}

.recordTitle2 {
  border: 1px solid #0000c0;
  border-width: 0 0 1px 0;
}

.recordTitle3 {
  border: 1px solid #ff7769;
  border-width: 0 0 1px 0;
}

.pie-chart {
  width: 100%;
  height: 400px!important;
  position: absolute;
  top: 30px!important;
}

.view-more-button {
  cursor: pointer;
}

.forward {
  width: 30px;
  height: 30px;
}

.chart-box {
  border: 1px solid #e0e0e0;
  // border-top: 0px;
}

.view-down {
  display: none!important;
}

.box-detail {
  display: none!important;
}

.active {
  background-color: green;
  color: white;
}

.gain {
  padding-left: 5.5vw;
  width: 25%;
}

.total-value {
  position: absolute;
  top: 220px;
}

@media (min-width: 541px) and (max-width: 767px) {

  //.total-value {
  //  position: absolute;
  //  top: 180px;
  //}

  .pie-chart {
    width: 100%;
    height: 400px!important;
    position: absolute;
    top: 30px!important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .pie-chart {
    width: 100%;
    height: 400px!important;
    position: absolute;
    top: 30px!important;
  }
}


@media (max-width: 540px) {
  .landing-after-login {
    box-sizing: border-box ;
    // width: 100vw;
    height: auto;
    background-image: none;
    background-color: #4FD18C;
    background-size: 100vw auto;
    background-repeat: no-repeat;
    background-attachment: local;
    // padding-top: 14%!important;
    padding-top: 70px!important;
    margin: 0;
    line-height: 1.5;
  }

  .chart-box {
    border: 1px solid #e0e0e0;
    // border-top: 0px;
  }

  .iconMPF-index {
    display: none;
  }

  // .menu-item {
  //   font-size: 8vw!important;
  // }

  .view-more-img {
    margin-right: 2.5vw;
    width: 24px;
    height: 14px;
  }

  .view-more {
    font-size: 4.3vw;
  }

  .personalMPF {
    font-size: 4.2vw;
    margin-bottom: 1.0rem;
  }

  .investOverview {
    font-size: 3.5vw;
    padding-bottom: 1.5vw;
  }

  .dateTitle {
    font-size: 3.5vw;
    padding-bottom: 1.5vw;
    color: #B7B7B7;
  }

  .manage-button {
    width: 20vw;
    height: 10.4vw;
  }

  .sub-title {
    font-size: 2.8vw;
  }

  .height-title {
    height: 60px;
    cursor: pointer;
  }

  .gain {
    padding-left: 5.5vw;
    width: 35%;
  }
  .sub-content {
    width: 100%;
    margin-right: 5px;
    padding-bottom: 1rem;
    font-size: 0.8rem;
    border: 1px solid #e0e0e0;
    border-width: 0 0 1px 0;
  }
  .other-fund {
    padding: 15px 0;
  }
  .text-danger {
    color: red;
  }
  .percentage {
    width: 10px;
    height: 10px;
    background-color: green;
    margin-right: 10px;
  }

  .core {
    background-color: #00FFFF;
  }

  .percentage-2 {
    background-color: #0000c0;
  }

  .percentage-3 {
    background-color: #ff7769;
  }

  .recordTitle1 {
    text-align: center;
    font-weight: bold;
    border: 1px solid green;
    border-width: 0 0 1px 0;
    margin-bottom: 0.5rem;
  }

  .core-content {
    border: 1px solid #00FFFF;
    border-width: 0 0 1px 0;
  }

  .recordTitle2 {
    border: 1px solid #0000c0;
    border-width: 0 0 1px 0;
  }

  .recordTitle3 {
    border: 1px solid #ff7769;
    border-width: 0 0 1px 0;
  }

  .pie-chart {
    width: 100%;
    height: 400px!important;
    position: absolute;
    top: 30px!important;
  }

  //.total-value {
  //  position: absolute;
  //  top: 180px;
  //}

  .forward {
    width: 20px;
    height: 20px;
  }

}
