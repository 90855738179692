.home { position: relative; padding:10px 35px; background:#fff;
    h1 { text-align: center; color:$corporateRedColor; font-size: 30px; padding:30px 0 5px 0; margin:0px; }
    p { text-align:center; font-size:14px; padding:5px 25px; margin:10px; }

    .bottom {
        padding: 30px 0;

        .section { padding:0px 25px;
            img { width:100%; }
            h6 { text-align:center; font-size:20px; padding:10px; color:$majorDarkGrey; }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .home {
        .bottom {
            padding-left: 45px;
            padding-right: 45px;
        }
    }
}

.horizontal-item {
  position: relative;
  text-align: center;
  color: black;
  margin: 1.5rem 1.5rem 1.5rem 0px;
  border: 0.5px solid $majorLightGrey;
  box-shadow: 3px 3px 5px $majorLightGrey;
  flex-direction: column;
  min-width: 280px;
  width: 280px;
  white-space: pre-wrap;
}

.quick-link-container {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;

    .quick-link-item {
        border: 1px solid $majorLightGrey;
        padding: 1rem 0.4rem;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

