///* Style for the form container */

// Form {
//   margin: 0 auto;
//   padding: 20px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
// }
//
//h2 {
//  margin-bottom: 20px;
//}

//input {
//  width: 100%;
//  padding: 8px;
//  margin-bottom: 15px;
//  border: 1px solid #ccc;
//  border-radius: 3px;
//}
//
button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-row label {
  flex: 1;
  margin-right: 10px;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.pagination-left {
  display: flex;
  align-items: center;
}

.pagination-left select {
  margin: 0 5px;
}

.pagination-right button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

.pagination-right span {
  margin: 0 10px;
}

.pagination-right button:hover {
  background-color: #0056b3;
}

.disable-true {
  color: gray!important;
}

.render-age {
  width: 243.5px!important;
  height: 40.25px!important;
  border: solid 1px #cccccc;
  padding: .375rem .75rem;
  border-radius: .25rem;
}

.sort-icon {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.5em;
}

.asc::before {
  content: "\2191"; /* Upward arrow */
}

.desc::before {
  content: "\2193"; /* Downward arrow */
}

