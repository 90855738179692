// material ui overfide
.material-search-text {
    >div { 
        &:before,
        &:after { display:none !important; }

        input[type=text] { padding:6px 10px !important; min-width:300px!important;  height:26px!important; }
    }
}

.material-select { padding:2px 0 !important;
    >div {
        &:before,
        &:after { display:none !important; }
    }
}

// Custom Header
.sort-active {
    color: #0a95ea !important;
}

// Custom Paginator
.custom-paging-root {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}
.custom-paging-toolbar {

}
.custom-paging-caption {
    font-size: 1rem !important;
}
.custom-paging-selectRoot {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem !important;
    select {
        width: 25px;
    }
}
.custom-paging-select {

}
.custom-paging-select {

}
.custom-paging-menuItem {

}

.pagination-button {
    margin: 0 10px !important;
    background-color: #fff !important;
    text-transform: none !important;
    font-size: 1rem !important;
}
.pagination-button:disabled {
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12) !important;
}

.enhanced-table-pagination {
    display: flex;
    align-items: center;
}
.pagination-location {
    font-size: 1rem !important;
    line-height: 1.37rem;
}

// Custom Select
.custom-select-container {
    width:50% !important;
}

.custom-select {
    padding: 6px 0 7px 7px !important;
    border:solid 1px #cccccc !important;
    color:#444 !important;
    background:#fff !important;
    background-clip: padding-box!important;
    font-family:$font-family-body!important;
    font-size: 18px!important;
    border-radius: .25rem !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
    -webkit-appearance:none !important;
    line-height: 1.5 !important;
    &:after {
        border:solid 1px #cccccc !important;
    }
    &:before {
        border:solid 1px #cccccc !important;
    }
    fieldset {
        border:solid 1px #cccccc !important;
    }
}

.custom-select-underline {
    display: none;
}

.select-multiple-checkbox {
    padding: 4px 0 7px 7px !important;
}

.select-multiple-checkbox-input {
    min-width: 400px;
    width: 650px;
}

.MuiInput-underline:after {
    display: none;
}

.MuiInput-underline:before {
    display: none;
}

.ag-theme-alpine {
    border: 0;
    --ag-odd-row-background-color: #fff;
    --ag-row-border-color: transparent;
    --ag-header-background-color: #fff;
    --ag-border-color: transparent;
    --ag-row-hover-color: transparent;
    --ag-range-selection-border-color: transparent;
}

.ag-header-cell-text {
    font-size: 1rem;
    font-weight: normal;
}

.ag-header-cell-label {
   justify-content: center;
}