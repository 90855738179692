// //ELEMENTS
 input[type=text],
 input[type=email],
 input[type=password],
 input[type=number],
 input[type=time],
 input[type=tel],
 textarea,
 select {
     margin:0;
     padding: .375rem .75rem;
     border:solid 1px #cccccc;
     color:#444;
     background:#fff;
     background-clip: padding-box;
     font-family:$font-family-body;
     font-size:18px;
     border-radius: .25rem;
     transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
     -webkit-appearance:none;
     line-height: 1.5;
 }

input[type=time] {
    padding: .34rem .375rem;
}

.errorMessage {text-align:left; color:$corporateRedColor; font-size: 1rem; }

#userForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 640px;
    padding: 0;
}

.form-wrapper {
    // padding:40px;
    font-size:20px;

    h4 { text-align:left; color:$corporateRedColor; }
    .grid {
        padding:20px;
        input { padding:5px 3px; background-color: transparent; border: 1px solid $majorGrey; border-width: 0 0 1px 0; border-radius: 0; margin-top: 1rem; }
        button { width:100%; background-color:$formBtnBgColor; color:#fff; font-size:20px; text-transform:none; border-radius:0; border-color: $formBtnBgColor; }
        a { text-align:center; font-size:20px; color:$formBtnColor; }
    }
    ul { width:100% !important;
        li { text-align:left !important; padding:5px; color:$majorDarkGrey; width:100% !important; }
    }
}

.form-item {
    line-height: 3em;
    button {
        font-size: 16px;
        width: auto;
        min-width: 120px;
        text-transform: none;
        border-radius: 5px;
        color: #fff;
        background-color:$majorDarkGrey;
    }

    button:hover {
        background-color:$majorDarkGreyHover;
    }

    input, textarea {
        width:100%;
    }
}

.mpf-form-item {
    width: 100%;
    line-height: 2em;
    color: $majorDarkGrey;
    font-size: 1rem;
    
    button {
        font-size: 16px;
        width: auto;
        min-width: 120px;
        text-transform: none;
        border-radius: 5px;
        color: #fff;
        background-color:$majorDarkGrey;
    }

    button:hover {
        background-color:$majorDarkGreyHover;
    }

    input, textarea, Select {
        padding: 0;
        width:100%;
        background-color: transparent;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
    }
}

.age-item {
    border: 1px solid lightgray;
    border-width: 0 0 1px 0;
}

.mpf-error-form-item {
    width: 100%;
    input, textarea {
        padding: 0;
        width:100%;
        background-color: transparent;
        border-color: $corporateRedColor;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
    }
}

.form-row {
    line-height: 3em;
}

.button-wrapper {
    width: 100%;
    justify-content: center;

    button {
        font-size: 16px;
        width: 120px;
        text-transform: none;
        border-radius: 5px;
        color: #fff;
        margin: 20px 20px 20px 0;
    }

    .primary-button {
        width: 100% !important;
        background-color:$primaryBtnBgColor;
    }
    .primary-button:hover {
        background-color:$primaryBtnHoverBgColor;
    }
    .primary-button:disabled {
        background-color:$majorLightGrey;
        border: 2px solid $majorGrey;
        color: #fff;
    }
    .second-button {
        width: auto !important;
        min-width: 120px !important;
        background-color:$majorDarkGrey;
    }
    .second-button:hover {
        background-color:$majorDarkGreyHover;
    }
    .second-button:disabled {
        background-color:$majorDarkGreyHover;
        color: #fff;
    }

    .import-button {
        background-color:$importBtnBgColor;
    }
    .import-button:hover {
        background-color:$importBtnHoverBgColor;
    }
    .import-button:disabled {
        background-color:$importBtnHoverBgColor;
        color: #fff;
    }

    .export-button {
        background-color:$exportBtnBgColor;
        width: auto !important;
    }
    .export-button:hover {
        background-color:$exportBtnHoverBgColor;
    }
    .export-button:disabled {
        background-color:$exportBtnHoverBgColor;
        color: #fff;
    }

    .remain-button{
        float: right;
        width: auto !important;
    }

    .delete-button {
        background-color:#FF0000;
    }
    .delete-button:hover {
        background-color:#f56464;
    }
    .delete-button:disabled {
        background-color:#f56464;
        color: #fff;
    }

    .send-button {
        width: auto !important;
        min-width: 120px;
        background-color:#36D900;
    }
    .send-button:hover {
        background-color:#78db58;
    }
    .send-button:disabled {
        background-color:#78db58;
        color: #fff;
    }

    .stop-button {
        background-color:#A301D9;
    }
    .stop-button:hover {
        background-color:#BF01FE;
    }
    .stop-button:disabled {
        background-color:#ebb3ff;
        color: #fff;
    }

    .back-button {
        width: auto !important;
        min-width: 120px;
        background-color:#B7B7B7;
    }

    .filter-button {
        background-color: white;
        border: $majorLightGrey 2px solid;
        border-radius: 20px;
        padding: 5px;
        display: flex;
        flex-direction: row;
    }

    .transparent-button {
        width: 100%;
        background-color: transparent;
        border: $primaryBtnBgColor 2px solid;
        color: black;
    }
}

.search-bar {
    input {
        border: none !important;
    }

    .button-area {
        display: flex;
        justify-content: flex-end;
    }
}

.select-multiple-checkbox{
    width: 400px;
}

.refer-friends-icon {
    width: 20px;
}

.hyperlink {
    text-decoration: underline;
    color: inherit;
    font-size: inherit;
}

.checkboxLabel {
    margin: 0;
}


