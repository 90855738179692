.register_container {
  box-sizing: border-box ;
  width: 100vw;
  height: 70px;
  background-image: url('../../images/Landingpg/Registration-period/Background 1.png');
  background-size: 100vw auto;
  background-repeat: no-repeat;
  background-attachment: local;
  margin-top: -70px;
  // padding-top: 7%!important;
  // margin: 0;
  // line-height: 1.5;
}

.sub_container {
  padding: 0 30px 30px;
  background-color: #F8F8F8;
  position: relative;
  width: calc(100% + 60px);
  left: -30px;

  @media (min-width: 540px) and (max-width: 950px) {
		margin-top: 2vw;
	}
}

.header-title-profile {
  font-size: 1.3rem;
  color: black;
  font-weight: lighter;
  background-color: #fff;
  position: relative;
  padding: 1.5vw;
  width: 100vw;
  left: calc(-50vw + 50%);
  // width: calc(100% + 60px);
  // left: -30px;
}

// @media (max-width: 539px) {
//   .register_container {
//     box-sizing: border-box ;
//     width: 100vw;
//     height: auto;
//     background-image: url('../../images/Landingpg/Registration-period/Mobile page/Background BEFORE login.png');
//     background-size: 102vw auto;
//     background-position: -1vw 0;
//     background-repeat: no-repeat;
//     background-attachment: local;
//     padding-top: 18%;
//     margin: 0;
//     line-height: 1.5;
//   }

//   .header-title-profile {
//     font-size: 1.3rem;
//     color: black;
//     font-weight: lighter;
//     background-color: #fff;
//     position: relative;
//     padding: 1rem;
//     width: 100vw;
//     left: calc(-50vw + 50%);
//     // width: calc(100% + 60px);
//     // left: -10px;
//   }
  
//   .sub_container {
//     padding: 0 30px 30px;
//     background-color: #F8F8F8;
//     margin-top: 9.5%;
//   }
// }
