.badge-container {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-top: 1rem;
  // margin-left: 5rem!important;
  // margin-right: 5rem!important;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

}
.badge-icon {
  width: 100%;
}
.font-container {
  font-size: 1.5rem;
}
.description-font {
  font-size: 1.3rem;
  color: rgb(40,43,62);
}
.black-content {
  font-size: 1.5rem;
  padding: 0 20px;

}

@media (max-width: 540px) {
  .badge-container {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    margin-top: 1rem;
    // margin-left: 0rem!important;
    // margin-right: 0rem!important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .badge-icon {
    width: 120px;
  }
  .font-container {
    font-size: 1rem;
  }
  .description-font {
    font-size: 0.9rem;
  }
  .black-content {
    font-size: 1rem;
    padding: 0 10px;
  }
}