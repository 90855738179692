/* grid */
.full img,
.fifty img,
.oneThird img,
.twoThird img,
.oneFourth img,
.oneFifth img,	
.oneSixth img,
.oneTenth img,
.thirty img,
.forty img,
.sixty img,
.seventy img            { width:100%; }		

.full					{ width:100%; }
.fifty					{ width:50% !important; }
.oneThird				{ width:33.33%; }
.twoThird				{ width:66.66%; }
.oneFourth				{ width:25%; }
.oneFifth				{ width:20%; }
.oneSixth				{ width:16.66%; }
.oneTenth				{ width:10%; }
.twenty 				{ width:20%; }
.thirty					{ width:30%; }
.forty					{ width:40%; }
.sixty					{ width:60%; }
.seventy				{ width:70%; }
.datetimePicker         { width:22.5% !important; }

/* sep */
.sep-0					{ height:0px; clear:both; padding:0; }
.sep-5					{ height:0px; clear:both; padding:2px 0 3px; }
.sep-10					{ height:0px; clear:both; padding:5px 0 5px; }
.sep-15					{ height:0px; clear:both; padding:7px 0 8px; }
.sep-20					{ height:0px; clear:both; padding:10px 0 10px; }
.sep-25					{ height:0px; clear:both; padding:15px 0 10px; }
.sep-30					{ height:0px; clear:both; padding:15px 0 15px; }
.sep-40					{ height:0px; clear:both; padding:20px 0 20px; }
.sep-60					{ height:0px; clear:both; padding:30px 0 30px; }
.sep-60					{ height:0px; clear:both; padding:30px 0 30px; }
.sepTwo		    		{ height:0px; clear:both; padding:0; display:none; }
.sepFour				{ height:0px; clear:both; padding:0; display:none; }
.sepThree				{ height:0px; clear:both; padding:0; display:none; }
.sepSeven				{ height:0px; clear:both; padding:0; display:none; }
.sep-line				{ height:1px; clear:both; padding:0; margin:20px 0 20px 0; }

/* margin */
.n1						{ margin:0 0 0 -1px; }
.p1						{ margin:0 0 1px 1px; }

.n20					{ margin:0 0 0 -20px; }
.p20					{ margin:0 0 20px 20px; }
.p30					{ margin:0 0 30px 30px; }

.p0                     { padding: 0px !important; }
.p1rem                  { padding: 1rem !important; }

.pt10					{ padding-top: 10px !important;}
.pt20					{ padding-top: 20px !important;}
.pt30					{ padding-top: 30px !important;}
.pt40					{ padding-top: 40px !important;}

.pl10					{ padding-left: 10px !important;}
.pl20					{ padding-left: 20px !important;}
.pl30					{ padding-left: 30px !important;}
.pl40					{ padding-left: 40px !important;}

.pr10					{ padding-right: 10px !important;}
.pr20					{ padding-right: 20px !important;}
.pr30					{ padding-right: 30px !important;}
.pr40					{ padding-right: 40px !important;}

.m0					    { margin: 0 !important;}

.ml5p					{ margin-left: 5% !important;}
.ml10					{ margin-left: 10px !important;}
.ml20					{ margin-left: 20px !important;}
.ml30					{ margin-left: 30px !important;}
.ml40					{ margin-left: 40px !important;}
.mt5p					{ margin-top: 5% !important;}
.mt10					{ margin-top: 10px !important;}
.mt20					{ margin-top: 20px !important;}
.mt30					{ margin-top: 30px !important;}
.mt40					{ margin-top: 40px !important;}