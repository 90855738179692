.game-container {
    width: 100%;
    // padding-top: 2rem;
	font-size: 1.1rem;

    .quiz-selection {
        border: 2px solid black;
        min-height: 5rem;
        padding: 1rem;
        align-items: center;
        margin-bottom: 1rem;
        cursor: pointer;
    }
}

.badge-dialog {
    top: 80px !important;

    .badge-image-container {
        z-index: 1 !important;
    }

    .badge-icon {
        width: 180px;
        height: 180px;
        position: relative;
        top: 15px;
    }

    .dialog-header {
        background-color: #34384a;
        height: 40px;
        position: relative;
        bottom: 80px;
        z-index: 0;
    }

    .dialog-body {
        background-color: white;
        padding: 1.5rem;
        flex-direction: column;
        position: relative;
        bottom: 80px;
        z-index: 0;
        font-size: 1.1rem;
    }
}

.video-container {
    width: 100%;
    
    // @media only screen and (max-width: 640px) {
    //     position: relative;
    //     width: calc(100% + 60px);
	//     left: -30px;
    // }
}

.video {
    position: relative;
    min-height: 40vh;
    max-width: 1000px;
    align-items: center;
    box-shadow: 2px 2px 5px #e0e0e0;
    
    @media only screen and (min-width: 640px) {
        aspect-ratio: 16/9;
    }
}

.mini-game-container {
    position: relative;
    // min-height: 40vh;
    max-width: 700px;
    align-items: center;
    box-shadow: 2px 2px 5px #e0e0e0;
}

.mission-title {
    font-size: 2.5rem;
    color: black;
    font-weight: bold;
    position: relative;
    padding: 32px;
    // width: 100vw;
    // left: calc(-50vw + 50%);
    width: calc(100% + 60px);
    left: -30px;
}