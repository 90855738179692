.logo {
    width: 20%;
    max-width: 300px;
    height: auto;
}

// .main__container {
//     width: 100%;
//     padding: 0 30px 30px;
// }

.ranking-container {
    border-radius: 25px;
    background-color: #151D29;
    padding: 2rem;
}

.title-bg {
    width: 100%;
    height: 100%;
}

.middle-line {
    color: #ffffff;
    margin-bottom: 2rem;
    width: 520px;
    height: 3px;
}

.rank1 {
    background-color: yellow;
}
.rank2 {
    background-color: lightgray;
}
.rank3 {
    background-color: orange;
}

.is-me {
    border-color: blue;
}
.rank1,
.rank2,
.rank3,
.is-me {
    border-radius: 10px;
    border-width: medium;
}
.nav-tab {
    border-radius: 19px;
    background-color: #000000;
    color: #ffffff;
    font-size: 1rem;
}
.content-title {
    width: 25%;
}
.flex-content {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 42px;
}
.overall {
    width: 124px;
    height: auto;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-attachment: local;
}
.text-white {
    color: #ffffff;
}
.acc-balance {
    font-size: 16px;
    padding-bottom: 1.2vw;
    letter-spacing: 2px;
}
.acc-money {
    font-size: 32px;
    padding-bottom: 0.5vw;
}
.acc-percent {
    font-size: 20px;
    padding-bottom: 0.5vw;
    align-items: center;
}
.ranking-ward {
    margin-left: 0.5rem;
    width: 10px;
    height: 10px;
}
.nav-tab1 {
    color: #ffffff;
    align-items: end;
}
.ranking-crown {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
}
.sub-money {
    font-size: 20px;
}
.number-title {
    position: relative;
    top: -1rem;
    font-weight: bold;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // font-size: 26px;
    // margin-bottom: 20px;
}
.sub-ward {
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
}

.table-content {
    color: #151D29;
}

.header-title {
    font-size: 1.3rem;
    color: black;
    font-weight: lighter;
    background-color: transparent;
    position: relative;
    padding: 1.5rem;
    width: calc(100% + 60px);
    left: -30px;

    @media (max-width: 540px) {
        padding: 1rem;
        width: calc(100% + 20px);
        left: -10px;
    }
}


.back-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin: 0 12px 0 5px;
}
.back-icon-content {
    font-size: 2.0rem;
}
.back-title {
    padding: 32px 0;
}

@media (max-width: 540px) {
    .main__container {
        width: 100%;
        padding: 0 10px 90px 10px;
    }

    .ranking-container {
        border-radius: 10px;
        background-color: #151D29;
        padding: 2rem 0.8rem;
        width: 90vw;
    }

    .title-bg {
        width: 100%;
        height: 100%;
    }

    .middle-line {
        color: #ffffff;
        margin-bottom: 2rem;
        width: 88vw;
        height: 3px;
    }

    .logo {
        width: 25%;
        height: auto;
    }

    .nav-tab {
        border-radius: 19px;
        background-color: #000000;
        color: #ffffff;
        font-size: 0.6rem;
    }

    .overall {
        width: 76px;
        height: auto;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-attachment: local;
    }
    .content-title {
        width: 25%;
    }

    .acc-balance {
        font-size: 12px;
        padding-bottom: 1.2vw;
        letter-spacing: 2px;
    }
    .acc-money {
        font-size: 20px;
        padding-bottom: 0.5vw;
    }
    .acc-percent {
        font-size: 14px;
        padding-bottom: 0.5vw;
        align-items: center;
    }
    // .ranking-ward {
    //     margin-left: 5px;
    //     width: 10px;
    //     height: 10px;
    // }
    .nav-tab1 {
        color: #ffffff;
        align-items: end;
    }
    .ranking-crown {
        width: 80px;
        height: auto;
        margin-bottom: 20px;
    }
    .sub-money {
        font-size: 16px;
    }
    .player-game {
        font-size: 12px;
    }
    // .number-title {
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     font-size: 18px;
    //     margin-bottom: 20px;
    // }

    .back-icon {
        width: 25px;
        height: 25px;
        cursor: pointer;
        margin: 0 5px 0 10px;
    }
    .back-icon-content {
        font-size: 4vw;
    }
    .flex-content {
        display: flex;
        justify-content: center;
        align-items: center;
        // height: 26px;
    }
    //.back-title {
    //    margin-top: 4.5rem!important;
    //}

}

@media (min-width: 541px) and (max-width: 640px) {
    .main__container {
        width: 100%;
        // padding: 0 10px 90px 10px;
    }

    .ranking-container {
        border-radius: 10px;
        background-color: #151D29;
        padding: 2rem 1.5rem;
        width: 90vw;
    }

    .title-bg {
        width: 100%;
        height: 100%;
    }

    .middle-line {
        color: #ffffff;
        margin-bottom: 2rem;
        width: 88vw;
        height: 3px;
    }

    .logo {
        width: 25%;
        height: auto;
    }

    .nav-tab {
        border-radius: 19px;
        background-color: #000000;
        color: #ffffff;
        font-size: 0.6rem;
    }

    .overall {
        width: 76px;
        height: auto;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-attachment: local;
    }
    .content-title {
        width: 25%;
    }

    .acc-balance {
        font-size: 14px;
        padding-bottom: 1.2vw;
        letter-spacing: 2px;
    }
    .acc-money {
        font-size: 20px;
        padding-bottom: 0.5vw;
    }
    .acc-percent {
        font-size: 16px;
        padding-bottom: 0.5vw;
        align-items: center;
    }
    // .ranking-ward {
    //     margin-left: 5px;
    //     width: 10px;
    //     height: 10px;
    // }
    .nav-tab1 {
        color: #ffffff;
        align-items: end;
    }
    .ranking-crown {
        width: 90px;
        height: auto;
        margin-bottom: 20px;
    }
    .sub-money {
        font-size: 18px;
    }
    // .number-title {
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     font-size: 18px;
    //     margin-bottom: 20px;
    // }

}
