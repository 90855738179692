.forgetPassword { position: relative; padding:10px 35px; background:#fff;
    h1 { text-align: center; color:$corporateRedColor; font-size: 30px; padding:0; margin:0px; }
    p { text-align:center; font-size:14px; padding:5px 25px; margin:10px; }
    .bottom { padding:30px 45px; 
        .section { padding:0px 25px;
            img { width:100%; }
            h6 { text-align:center; font-size:20px; padding:10px; color:$majorDarkGrey; }
        }
    }
}
