.container {
    //padding-top: 2rem;
    color: $majorDarkGrey;
    font-size: 1.2rem;
    
    .section-title {
        //font-weight: 500;
        font-weight: 600;
    }

    .section-type {
        padding-top: 1.1rem;
    }

    .section-selection {
        margin-top: 1rem;
    }

    .selection-title{
        color: $majorDarkGreyHover;
    }

    .selection-content{
        margin-top: 0.8rem;
    }

    .section-header {
        position: relative;
        background-color: white;
        justify-content: center;
    }

    .stripe {
        background-color: white;
        height: 100%;
        position: relative;
    }

    .stripe:before {
        content:"";
        background-color: white;
        position: absolute;
        height: 100%;
        width: calc(100% + 60px);
	    left: -30px;
        z-index: -1;
    }

    .percentage-input {
        background-color: transparent;
        width: 60%;
        border: none;
        padding: 0;
        border-bottom: $majorLightGrey 1px solid;
        padding: 3px;
        padding-bottom: 0;
        font: inherit;
        color: inherit;
        max-width: 4rem;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }

    .risk-index-icon {
        width: 95px;
    }
}

.fund-manager {
    // margin-top: 4rem!important;
    margin-top: 70px !important;
}

.large-font-size {
    font-size: 1.7rem;
}

.medium-font-size {
    font-size: 1.5rem;
}

.small-font-size {
    font-size: 1rem;
}

.bold {
    font-weight: bold;
    color: $majorDarkGreyHover;
}

.black {
    color: black;
    border: 2px solid darkgray;
}

.black-text {
    color: black;
}

.light-black {
    color: rgba($color: #000000, $alpha: 0.8)
}

.dark-grey-hover {
    color: $majorDarkGreyHover;
}

.step-icon {
    width: 30px;
    height: 30px;
    margin-right: 3px;
}

.quarterly {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; 
    color: black;
}

.strike > span {
    position: relative;
    display: inline-block;
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: $majorLightGrey;
}

.strike > span:before {
    right: 100%;
    margin-right: 20px;
}

.strike > span:after {
    left: 100%;
    margin-left: 20px;
}

.fund-footer-container {
	width: 100%;
	background-color: white;
	overflow: hidden;
	position: fixed;
	bottom: 0;
	z-index: 100;
    padding: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
    box-shadow: 0 0px 2px rgba($color: #000000, $alpha: 0.3);
}

.full-hr {
    position: relative;
    width: calc(100% + 60px);
	left: -30px;
}

.error {
    color: red;
}

.link {
    text-decoration-color: #01a758;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
}

.filter-selections {
    border: 2px solid;
    border-radius: 1rem;
    margin: 1rem 0.8rem 1rem 0;
    cursor: pointer;
}

.filter-selections:hover {
    border-color: #01a758 !important;
}

// .makeStyles-root-25 .MuiSvgIcon-root {
//     top: 0!important;
// }

// @media (max-width: 540px) {
//     .fund-manager {
//         margin-top: 3.5rem !important;
//     }
// }

.gray-cell {
    background-color: #fafafa;
    width: 100%;
    padding: 0.5rem;
}

.fund-price-page {
    font-size: 1.2rem;

    .risk-icon {
        width: 95px;
    }
    
    .sub-text {
        font-size: 0.9rem;
        color: gray;
        align-items: flex-end;
    }

    @media (max-width: 540px) {
        font-size: 0.9rem;

        .sub-text {
            font-size: 0.75rem;
            color: gray;
            align-items: flex-end;
        }

        .risk-icon {
            width: 80px;
        }
    }
}

.risk-class-table {
    text-align: center;
    background-color: white;
    color: black;
    
    table {
        border: #e9e9e9 1px solid;
    }

    th {
        border: #e9e9e9 1px solid;
        color: black !important;
        text-align: center;
        vertical-align: middle;
        padding: 10px;
        position: relative;
        background-color: white;
    }

    td {
        border: #e9e9e9 1px solid;
        color: black !important;
        text-align: center;
        vertical-align: middle;
        padding: 10px 5px;
        min-width: 50px;
        position: relative;
        background-color: #fafafa;
    }
}
