

.dropdownTitle {
  border-bottom: 1px solid darkgray;
}

.dropdownContent {
  padding: 10px;
}

.MuiDrawer-paperAnchorTop {
  //top: 10vw!important;
  left: 0;
  right: 0;
  bottom: auto;
  height: auto;
  max-height: 100%;
}

.login-page {
  box-sizing: border-box ;
  background-image: url('../../images/Landingpg/Registration-period/Background 1.png');
  background-size: 100vw auto;
  background-repeat: no-repeat;
  background-attachment: local;
  padding-top: 70px;
  margin-top: -70px;
  line-height: 1.5;
}

// .MuiSvgIcon-root {
//   top: 20px;
// }



 @media (max-width: 539px) {
   .login-page-button {
     color: white;
     width: 6vw!important;
     height: 2vw;
     font-size: 15vw;
     min-width: 48px;
     border-style: solid;
     border-radius: 3vw;
     background-color: black;
   }

   .login-page {
     box-sizing: border-box ;
     width: 100vw;
     height: auto;
     background-image: url('../../images/Landingpg/Registration-period/Mobile page/Background BEFORE login.png');
     background-size: 102vw auto;
     background-position: -1vw 0;
     background-repeat: no-repeat;
     background-attachment: local;
     line-height: 1.5;
   }

 }

