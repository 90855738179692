
.header-reward-content {
   font-size: 2.5rem;
   color: #33A35B;
   font-weight: bold;
   position: relative;
   padding: 32px;
   width: calc(100% + 60px);
   left: -30px;
    margin: 0px!important;
}

.reward-content {
  background-color: #33A35B;
  border-radius: 2rem 0px 0px 2rem;
  justify-content: center;
  color: #ffffff;
  padding: 2rem
}

.reward-button {
  // height: 250px;
  height: 100%;
  background-color: #151D29;
  color: #ffffff;
  justify-content: center;
  border-radius: 0px 2rem 2rem 0px;
  button {
    font-size: 16px;
    width: 120px;
    text-transform: none;
    border-radius: 5px;
    color: #fff;
    margin: 20px 20px 20px 0;
  }
}

.reward-title-font {
  font-size: 1.7rem;
  color: white;
}

.logo-container {
  margin-bottom: 2rem;
}

@media (max-width: 540px) {
  .header-reward-back {
    font-size: 1.3rem;
    color: black;
    font-weight: bold;
    position: relative;
    padding: 16px;
    width: calc(100% + 60px);
    left: -20px;
    margin-top: 3rem!important;

  }
  .header-reward-content {
    font-size: 1.5rem;
    color: #33A35B;
    font-weight: lighter;
    position: relative;
    padding: 16px;
    width: calc(100% + 60px);
    left: -20px;
    margin: 0px!important;
  }

  .reward-content {
    height: 100%;
    background-color: #33A35B;
    border-radius: 1.5rem 0px 0px 1.5rem;
    justify-content: center;
    padding: 2rem;
    color: #ffffff;
  }

  .reward-button {
    height: 100%;
    background-color: #151D29;
    color: #ffffff;
    justify-content: center;
    border-radius: 0px 1.5rem 1.5rem 0px ;
    button {
      font-size: 16px;
      width: 120px;
      text-transform: none;
      border-radius: 5px;
      color: #fff;
      margin: 20px 20px 20px 0;
    }
  }

  .reward-title-font {
    font-size: 1rem;
    color: white;
  }

  .logo-container {
    margin-bottom: 0!important;
  }

}
