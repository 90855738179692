//<link href="//db.onlinewebfonts.com/c/d41ac2c403d35751ee2c3721d82a1e3c?family=jf-openhuninn-1.1" rel="stylesheet" type="text/css"/>

.landing-container {
    //box-sizing: border-box ;
    //width: 100vw;
    //height: auto;
    //background-image: url('../../images/Landingpg/Registration-period/Background 1.png');
    //background-size: 100vw auto;
    //background-repeat: no-repeat;
    //background-attachment: local;
    //padding-top: 10%;
    //margin: 0;
    //line-height: 1.5;
}

//@font-face {
//   font-family: "jf-openhuninn";   /*Can be any text*/
//   font-style: normal;
//   font-weight: 400;
//   src: local("jf-openhuninn-1.1"),
//   url(../../font/jf-openhuninn-1.1.ttf) format('truetype');
//}


.landing-content {
    box-sizing: border-box ;
    width: 100%;
    // position: relative;
    // left: calc(-50vw + 50%);
    height: auto;
    background-image: url('../../images/Landingpg/Registration-period/Background 1.png');
    background-size: 100vw auto;
    background-repeat: no-repeat;
    background-attachment: local;
    padding-top: 70px;
    margin: 0;
    line-height: 1.5;
    // padding-bottom: 5vw;

    &.landing-content-eng {
        background-image: url('../../images/Landingpg/Registration-period/Background 1.png');
    }

    .iconMPF {
        margin-top: 2vw;
        text-align: center;
        padding-bottom: 2vw;

        img {
            width: 26vw;
            height: auto;
        }
    }

    .text {
        text-align: center;

        p {
            // font-family: 'jf-openhuninn';
            padding: 0;
            margin: 0;
            color: white;
            font-size: 1.5vw;
            letter-spacing: 0.2vw;
        }

        &.text-eng {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            p {

                width: 80%;
            }
        }
    }

    .text-green {
        // font-family: 'jf-openhuninn';
        font-weight: 500;
        color: #64BA7B;
        font-size: 1.5vw;
        letter-spacing: 0.3vw;
        background-color: white;
        //width: 20vw;
        padding: 1.2vw 3vw;
        border-radius: 4vw;
        box-shadow: 2px 3px 5px black;
    }

    .text-green-eng {
        font-weight: bold;
    }

    .align-items-center {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 2vw;
    }

    .participation-container {
        width: 100%;
        text-align: center;
        margin-top: 2vw;
        color: white;
        position: relative;

        div {
            position: absolute;
            width: 100%;
        }

        .participation-1 {
            // font-family: 'jf-openhuninn';
            letter-spacing: 0.3vw;
            font-weight: bold;
            font-size: 2vw;
            top: -1vw;
        }

        .participation-2 {
            // font-family: 'jf-openhuninn';
            //font-family: 'MYuenHK-Xbold';
            font-size: 1.3vw;
            top: 1.6vw;
        }
        .letterCHI {
            letter-spacing: 0.3vw;
        }
    }



    .game-info {
        width: 100%;
        text-align: center;
        img {
            width: 80%;
            height: auto;
        }  
    }
    .game-info-mobile {
        display: none;
    }

    .footer-Mobi {
        display: none;
    }
    

    .stand {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 4.5vw 0 3vw;
        // font-family: 'MYuenHK-Xbold';
        p {
            width: 50%;
            background-color: #64BA7B;
            text-align: center;
            font-size: 2.5vw;
            letter-spacing: 0.3vw;
            color: white;
            border: 0.4vw solid rgb(248, 247, 247);
            box-shadow: 0.4vw 0.4vw 1vw black;
            border-radius: 5vw;
            padding: 1vw 0;
            margin: 0;
        }

        &.stand-eng {
            p {
                width: 70%;
                font-size: 2vw;
            }
        }


    }

    .HK-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2vw;
        img {
            width: 30vw;
        }

        span {
            text-align: center;
            width: 15%;
            font-size: 15vw;
            color: #64BA7B;
        }
    }

    .howToPlay {
        text-align: center;
        margin: 3vw 0 0;
        img {
            width: 24%;
        }
    }

    .howToPlayMobi {
        display: none;
    }

    .step {
        width: 100%;
        padding: 3vw 4vw 3vw;

        &.step-eng {
            padding-top: 5vw;

        }
    }


    .landing-button-join {
        background-image: url('../../images/Landingpg/Registration-period/Joinbutton.png');
        width: 300px
    }

    // .prize {
    //     text-align: center;
    //     margin-top: 1vw;
    //     margin-bottom: 2vw;
    //     img {
    //         width: 25%;
    //         margin-bottom: 2vw;
    //     }

    //     p {
    //         // font-family: 'jf-openhuninn';
    //         padding: 0;
    //         margin: 0;
    //         color: white;
    //         font-size: 1.6vw;
    //         letter-spacing: 0.2vw;
    //     }
        
    // }

    .prize-eng {
        text-align: center;
        margin-top: -1vw;
        margin-bottom: 1vw;
    }

    .prizeMobi {
        display: none;
    }

    .rank {
        //height: 65vw;
        height: 53vw;
        position: relative;
        
        .rank-1-2-3 {
            display: flex;
            justify-content: center;
            padding: 1.5vw 3vw;
            width: 100%;

            .rank-1st {
                width: 40%;
                text-align: center;

                img {
                    width: 40%;
                }

                > div {
                    display: flex;
                    justify-content: center;
                }

                .border-1st {
                    width: 88%;
                    border: 0.5vw solid white;
                    box-shadow: 0.4vw 0.6vw 1vw black;
                    background-color: black;
                    border-radius: 5vw;
                    color: white;
                    padding: 0.7vw 0;
                    margin-top: 1.2vw;

                    p, div {
                        margin: 0;
                        letter-spacing: 0.3vw;
                    }

                    p {
                        font-size: 1.3vw;
                    }

                    div {
                        font-size: 1.5vw;
                        // font-family: 'MYuenHK-Xbold';
                    }

                    &.border-1st-eng {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        p {
                            width: 75%;
                            font-size: 1.6vw;
                            letter-spacing: 0.1vw;
                            font-weight: bold;
                        }

                        div {
                            font-size: 1.1vw;
                            letter-spacing: 0.1vw;
                            font-weight: bold;
                            // font-family: Calibri;
                        }
                    }
                    
                }
            }

            .wraperRank-2nd-3rd {
                width: 30%;
                position: relative;

                .rank-2nd-3rd {
                    // display: flex;
                    // justify-content: center;
                    text-align: center;
                    width: 100%;
                    position: absolute;
                    top: 8vw;

                    img {
                        width: 40%;
                    }

                    > div {
                        display: flex;
                        justify-content: center;
                    }

                    .border-2rd-5th {
                        width: 80%;
                        border: 0.5vw solid white;
                        box-shadow: 0.4vw 0.6vw 1vw black;
                        background-color: black;
                        border-radius: 5vw;
                        color: white;
                        padding: 0.7vw 0;
                        margin-top: 0.5vw;


                        p, div {
                            margin: 0;
                            letter-spacing: 0.3vw;
                        }
    
                        p {
                            font-size: 1.3vw;
                        }

                        div {
                            font-size: 1.5vw;
                            // font-family: 'MYuenHK-Xbold';
                        }

                        &.border-2rd-5th-eng {
                            width: 60%;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            p {
                                width: 75%;
                                font-size: 1.6vw;
                                letter-spacing: 0.1vw;
                                font-weight: bold;
                            }

                            div {
                                font-size: 1.1vw;
                                letter-spacing: 0.1vw;
                                font-weight: bold;
                                // font-family: Calibri;
                            }
                        }
                    }
                }

            }
        }
        .rank-1-2-3-Mobi {
            display: none;
        }

        .rank-4-5 {
            width: 100%;
            display: flex;
            justify-content: center;
            position: absolute;
            top: 32vw;

            .rank-4th-5th {
                text-align: center;
                width: 30%;

                img {
                    width: 30%;
                }

                > div {
                    display: flex;
                    justify-content: center;
                }

                .border-2rd-5th {
                    width: 70%;
                    border: 0.5vw solid white;
                    box-shadow: 0.4vw 0.6vw 1vw black;
                    background-color: black;
                    border-radius: 5vw;
                    color: white;
                    padding: 0.7vw 0;
                    margin-top: 0.5vw;

                    p, div {
                        margin: 0;
                        letter-spacing: 0.3vw;
                    }

                    p {
                        font-size: 1.3vw;
                    }

                    div {
                        font-size: 1.5vw;
                        // font-family: 'MYuenHK-Xbold';
                    }

                    &.border-2rd-5th-eng {
                        width: 60%;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        p {
                            width: 75%;
                            font-size: 1.6vw;
                            letter-spacing: 0.1vw;
                            font-weight: bold;
                        }

                        div {
                            font-size: 1.1vw;
                            letter-spacing: 0.1vw;
                            font-weight: bold;
                            // font-family: Calibri;
                        }
                    }
                }
            }
        }

    }

    .star {
        width: 100%;
        height: 11vw;
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 6vw !important;

        img {
            position: absolute;
            bottom: 0;
            width: 14vw;
        }
    }

    .star-eng {
        width: 100%;
        height: 11vw;
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 2vw !important;

        img {
            position: absolute;
            bottom: 0;
            width: 11.5vw;
        }
    }

    .result {
        width: 100%;
        height: auto;
        padding: 0 15%;
        margin-top: 2vw;

        .result-1-2-3 {
            display: flex;
            justify-content: space-around;
            width: 100%;
            padding: 0;

            img {
                width: 26%;
            }
        }

        .result-4-5 {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 0;
            margin-top: 0.8vw;

            img {
                width: 26%;
                margin: 0 1.5vw;
            }
        }

        .result-6 {
            width: 100%;
            text-align: center;
            padding: 0;
            margin-top: 0.8vw;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            img {
                width: 40%;
                padding: 0;
                margin: 0;
            }

            p {
                width: 100%;
                font-weight: bold;
                font-size: 1.3vw;
                letter-spacing: 0.1vw;
                padding: 0;
                margin: 1vw 0 1.5vw;
            }

            .draw-result-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                width: 20vw;
                border: 0.1vw solid rgba(94, 91, 91, 0.116);
                border-radius: 5vw;
                box-shadow: 0.2vw 0.3vw 0.5vw black;
                background-color: white;

                .draw-result {
                    width: 100%;
                    // font-family: 'MYuenHK-Xbold';
                    font-size: 2vw;
                    letter-spacing: 0.2vw;
                }
                .draw-result-note {
                    width: 100%;
                    font-size: 1.2vw;
                }
            }

            &.result-6-eng {
                margin-bottom: 2vw;
            }
            
            .draw-result-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                width: 20vw;
                border: 0.1vw solid rgba(94, 91, 91, 0.116);
                border-radius: 5vw;
                box-shadow: 0.2vw 0.3vw 0.5vw black;
                background-color: white;

                .draw-result {
                    width: 100%;
                    // font-family: 'MYuenHK-Xbold';
                    font-size: 1.5vw;
                    letter-spacing: 0.2vw;
                    border: none;
                    border-radius: 0;
                }
                .draw-result-note {
                    width: 100%;
                    font-size: 1.2vw;
                    border: none;
                    border-radius: 0;
                }
            }

        }


    }

    
    .footer {
        width: 100%;
        padding: 3.5vw 2vw 0;

        p {
            padding: 0;
            margin: 0;
            font-size: 1vw;
            height: 1.3vw;
        }
        .display-none {
            display: none;
        }

        .next {
            position: relative;
            height: 9vw;

            .icon-next {
                position: absolute;
                right: 0;
                top: 3.5vw;
                right: 2vw;
                //font-size: 500%;
                font-size: 6vw;
                color: white;
            }
        }

        &.footer-eng {
            .text-desktop {
                margin-top: -2vw;
            }
        }

        &.footer-chi {
            .text-desktop {
                margin-top: -1vw;
            }
        }
    }
}


@media (max-width: 1530px) {
    .landing-content {
        .result {
            .result-6 {
                &.result-6-eng {
                    margin-bottom: 1.5vw;
                }
            }
        }
        .footer {
            padding: 2.5vw 2vw 0;
        }
    }
}

@media (max-width: 1200px) {
    .landing-content {
        .result {
            .result-6 {
                &.result-6-eng {
                    margin-bottom: 3.2vw;
                }
            }
        }
        .footer {
            padding: 0 2vw ;
        }
    }
}

@media (min-width: 768px) and (max-width: 979px) {
    .landing-content {
        line-height: 1.4;
        .game-info-mobile {
            display: none;
        }
        .howToPlayMobi {
            display: none;
        }
        .step {
            padding: 3vw 4vw 3.8vw;
        }
        .prizeMobi {
            display: none;
        }
        .rank {
            .rank-1-2-3-Mobi {
                display: none;
            }
        }
        .result {
            .result-6 {
                .draw-result-container {
                    width: 20vw;
                }
                &.result-6-eng {
                    margin-bottom: 1vw;
                }
            }

        }

        .footer-Mobi {
            display: none;
        }
        .footer {
            padding: 0 2vw ;
            p {
                height: 1.4vw;
            }
            .next {
                .icon-next {
                    right: 1vw;
                    top: 6vw;
                }
            }
        }
    }

}

@media (min-width: 645px) and (max-width: 767px) {
    .landing-content {
        line-height: 1.3;
        .game-info-mobile {
            display: none;
        }
        .howToPlayMobi {
            display: none;
        }
        .step {
            padding: 4vw 4vw 3.6vw;
        }
        .prizeMobi {
            display: none;
        }
        .rank {
            .rank-1-2-3-Mobi {
                display: none;
            }
        }
        .result {
            .result-6 {
                .draw-result-container {
                    width: 20vw;
                }
                &.result-6-eng {
                    margin-bottom: 2vw;
                }
            }

        }
        .footer-Mobi {
            display: none;
        }
        .footer {
            p {
                height: 1.5vw;
            }
            .next {
                position: relative;
                .icon-next {
                    position: absolute;
                    top: 4vw;
                    right: 2vw;
                }
            }
        }
    }
}

@media (min-width: 580px) and (max-width: 644px) {
    .landing-content {
        line-height: 1.3;

        .step {
            padding: 4vw 4vw 3.6vw;
        }
        .result {
            .result-6 {
                .draw-result-container {
                    width: 20vw;
                }
                &.result-6-eng {
                    margin-bottom: 2vw;
                }
            }

        }

        .footer {
            p {
                height: 1.3vw;
            }
            .next {
                position: relative;
                .icon-next {
                    position: absolute;
                    top: 1.7vw;
                    right: 2vw;
                }
            }
        }
    }
}

@media (min-width: 540px) and (max-width: 579px) {
    .landing-content {
        line-height: 1.2;

        .step {
            padding: 4vw 4vw 3.6vw;
        }

        .result {
            .result-6 {
                margin-bottom: 1.7vw;
            }
        }

        .footer {
            p {
                height: 1.3vw;
            }
            .next {
                position: relative;
                .icon-next {
                    position: absolute;
                    top: 3vw;
                    right: 2vw;
                }
            }
        }
    }
}

@media (max-width: 539px) {
    .landing-content {
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background-image: url('../../images/Landingpg/Registration-period/Mobile page/Background BEFORE login.png');
        background-size: 102vw auto;
        background-position: -1vw 0;
        background-repeat: no-repeat;
        background-attachment: local;
        padding-top: 70px;
        margin: 0;
        line-height: 1.5;

        .iconMPF {
            text-align: center;
            //padding-bottom: 7vw;
            margin-bottom: 1vw;

            img {
                width: 40vw;
                height: auto;
            }
        }

        .text {
            text-align: center;

            p {
                padding: 0;
                margin: 0;
                color: white;
                font-size: 3vw;
                letter-spacing: 0.5vw;
            }
        }

        .text-green {
            color: green;
            font-size: 2vw;
            letter-spacing: 0.3vw;
            background-color: white;
            width: 25vw;
            padding: 2.5vw 0;
            border-radius: 4vw;
        }

        .align-items-center {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-top: 8vw;

            &.align-items-center-eng {
                margin-top: 2vw;
            }
        }

        &.landing-content-eng {
            background-image: url('../../images/Landingpg/Registration-period/Mobile page/Background BEFORE login.png');
        }

        .game-info {
            display: none;
        }

        .game-info-mobile {
            display: block;
            margin-top: 18vw;
            margin-bottom: 12vw;
            width: 100%;
            text-align: center;

            img {
                width: 80%;
                height: auto;
            }
        }

        .stand {
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            margin: 2vw 0 1vw;

            p {
                width: 80%;
                background-color: #64BA7B;
                text-align: center;
                font-size: 4.5vw;
                letter-spacing: 0.3vw;
                color: white;
                border: 0.8vw solid rgb(248, 247, 247);
                box-shadow: 0 2px 4px rgba(0, 0, 0, 1);
                border-radius: 10vw;
                padding: 2vw 0;
                margin-top: 1vw;
            }

            &.stand-eng {
                margin-bottom: 3vw;
                p {
                    font-size: 2.5vw;
                }
            }
        }

        .HK-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 25vw;

            img {
                width: 30vw;
            }

            span {
                text-align: center;
                width: 15%;
                font-size: 15vw;
                color: #64BA7B;
            }
        }

        .HK-container-cn {
            margin-top: 20vw;
        }
        
        .HK-container-non-registration {
            margin-top: 38vw !important;
        }

        .howToPlay {
            text-align: center;
            margin: 5vw 0 0;

            img {
                width: 50%;
            }
        }

        .step {
            display: none;
        }

        .howToPlayMobi {
            display: block;
            text-align: center;
            margin: 10vw 0 12vw;

            img {
                width: 78%;
            }

            &.howToPlayMobi-eng {
                margin: 10vw 0 20vw;
            }
        }


        .landing-button-join {
            background-image: url('../../images/Landingpg/Registration-period/Joinbutton.png');
            width: 10vw;
        }

        .prizeDesk {
            display: none;
        }

        .prizeMobi {
            display: block;

            &.prizeMobi-eng {
                width: 100%;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                p {
                    width: 80%;
                }
            }
        }

        // .prize {
        //     text-align: center;
        //     // margin-top: 35vw;

        //     img {
        //         width: 40%;
        //         margin-top: 5vw;
        //         margin-bottom: 5vw;
        //     }

        //     p {
        //         // font-family: 'jf-openhuninn';
        //         padding: 0;
        //         margin: 0;
        //         color: white;
        //         font-size: 2.6vw;
        //         letter-spacing: 0.2vw;
        //     }

        // }

        .rank {
            height: 162vw;
            margin-top: 8vw;

            .rank-1-2-3 {
                display: none;
            }

            .rank-1-2-3-Mobi {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                // padding: 1vw 3vw;
                width: 100%;

                .rank-1st {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    img {
                        width: 30vw;
                        height: 100%;
                    }

                    .border-1st {
                        width: 100%;
                        border: 1vw solid white;
                        box-shadow: 0.6vw 0.8vw 1.5vw black;
                        background-color: black;
                        border-radius: 10vw;
                        color: white;
                        padding: 3vw 0;
                        margin: 1.2vw 20vw 0;

                        p, div {
                            margin: 0;
                            font-size: 2.5vw;
                            letter-spacing: 0.5vw;
                        }

                        p {
                            font-size: 2vw;
                        }

                        &.border-1st-eng {
                            margin: 2vw 12vw 0;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            padding: 1vw 0;
                            p {
                                width: 80%;
                                font-size: 4vw;
                                letter-spacing: 0.1vw;
                                font-weight: bold;
                            }

                            div {
                                font-size: 2.4vw;
                                letter-spacing: 0.1vw;
                                font-weight: bold;
                                // font-family: Calibri;
                            }
                        }

                    }


                }

                .rank-2-3-Mobi {
                    display: flex;
                    justify-content: space-evenly;
                    width: 100%;
                    height: auto;
                    margin-top: 8vw;

                    .wraperRank-2nd-3rd {
                        width: 40vw;

                        .rank-2nd-3rd {
                            text-align: center;
                            width: 100%;

                            img {
                                width: 25vw;
                            }

                            > div {
                                display: flex;
                                justify-content: center;
                            }

                            .border-2rd-5th {
                                width: 100%;
                                border: 1vw solid white;
                                box-shadow: 0.6vw 0.8vw 1.5vw black;
                                background-color: black;
                                border-radius: 10vw;
                                color: white;
                                padding: 2.5vw 0;
                                margin-top: 1vw;

                                p, div {
                                    margin: 0;
                                    font-size: 2.5vw;
                                    letter-spacing: 0.5vw;
                                }

                                p {
                                    font-size: 2vw;
                                }

                                &.border-2rd-5th-eng {
                                    width: 80%;
                                    display: flex;
                                    justify-content: center;
                                    flex-wrap: wrap;
                                    padding: 1.5vw 0;
                                    p {
                                        width: 75%;
                                        font-size: 4vw;
                                        letter-spacing: 0.1vw;
                                        font-weight: bold;
                                    }

                                    div {
                                        font-size: 2.4vw;
                                        letter-spacing: 0.1vw;
                                        font-weight: bold;
                                        // font-family: Calibri;
                                    }
                                }
                            }
                        }

                    }
                }

            }

            .rank-4-5 {
                display: flex;
                position: static;
                justify-content: space-evenly;
                width: 100%;
                margin-top: 7vw;

                .rank-4th-5th {
                    text-align: center;
                    width: 40vw;

                    img {
                        width: 18vw;
                    }

                    > div {
                        display: flex;
                        justify-content: center;
                    }

                    .border-2rd-5th {
                        width: 100%;
                        border: 1vw solid white;
                        box-shadow: 0.6vw 0.8vw 1.5vw black;
                        background-color: black;
                        border-radius: 10vw;
                        color: white;
                        padding: 2.5vw 0;

                        p, div {
                            margin: 0;
                            font-size: 2.5vw;
                            letter-spacing: 0.5vw;
                        }

                        p {
                            font-size: 2vw;
                        }

                        &.border-2rd-5th-eng {
                            width: 80%;
                            padding: 1.5vw 0;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            p {
                                width: 75%;
                                font-size: 4vw;
                                letter-spacing: 0.1vw;
                                font-weight: bold;
                            }

                            div {
                                font-size: 2.4vw;
                                letter-spacing: 0.1vw;
                                font-weight: bold;
                                // font-family: Calibri;
                            }
                        }
                    }
                }
            }

        }

        .star {
            margin-top: 21vw !important;
            width: 100%;
            height: 25vw;
            position: relative;
            display: flex;
            justify-content: center;

            img {
                position: absolute;
                bottom: 0vw;
                width: 33vw;
            }
        }

        .star-eng {
            margin-top: 20vw !important;
            width: 100%;
            height: 25vw;
            position: relative;
            display: flex;
            justify-content: center;

            img {
                position: absolute;
                bottom: 0vw;
                width: 33vw;
            }
        }

        .result {
            width: 100%;
            padding: 0 20vw;

            .result-1-2-3 {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 100%;
                padding: 0;
                margin-top: 4.5vw;

                img {
                    width: 85%;
                    height: 18vw;
                    padding-bottom: 3.5vw;
                }
            }

            .result-4-5 {
                display: flex;
                justify-content: center;
                width: 100%;
                flex-wrap: wrap;
                padding: 0;

                img {
                    width: 85%;
                    height: 18vw;
                    padding-bottom: 3.5vw;
                }
            }

            .result-6 {
                width: 100%;
                text-align: center;
                padding: 0;

                img {
                    width: 100%;
                    height: 12.5vw;
                }

                p {
                    font-weight: 600;
                    font-size: 2.5vw;
                    letter-spacing: 0.5vw;
                    margin: 0;
                    padding: 3vw 0 8vw;
                }

                //span {
                //    padding: 2.5vw 12vw;
                //    font-weight: 600;
                //    font-size: 3vw;
                //    letter-spacing: 0.5vw;
                //    background-color: white;
                //    border: 0.2vw solid rgba(94, 91, 91, 0.116);
                //    border-radius: 5vw;
                //    box-shadow: 0.4vw 0.6vw 1vw black;
                //}


                .draw-result-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50vw;
                    border: 0.1vw solid rgba(94, 91, 91, 0.116);
                    border-radius: 10vw;
                    box-shadow: 0.4vw 0.6vw 1vw black;
                    background-color: white;

                    .draw-result {
                        font-size: 3vw;
                        font-weight: bolder;
                        border-color: transparent;
                        box-shadow: none;
                    }

                    .draw-result-note {
                        border-color: transparent;
                        box-shadow: none;
                        font-size: 3vw;
                    }
                }

                span {
                    // font-family: 'MYuenHK-Xbold';
                    //padding: 1vw 5vw;
                    margin: 2vw 5vw;
                    font-weight: 600;
                    font-size: 3.8vw;
                    letter-spacing: 0.2vw;
                    //border: 0.1vw solid rgba(94, 91, 91, 0.116);
                    //border-radius: 5vw;
                    //box-shadow: 0.2vw 0.3vw 0.5vw black;
                    //background-color: white;
                }

            }
        }

        .footer {
            display: none;
        }

        .footer-Mobi {
            display: block;
            width: 100%;
            height: 100vw;

            .text {
                width: 100%;
                margin-top: 9vw;


                p {
                    color: #5e5e5e;
                    text-align: start;
                    padding: 0 6vw;
                    margin: 0;
                    font-size: 2.5vw;
                    letter-spacing: 0.3vw;
                }
            }


            .next {
                margin-top: 3vw;
                padding: 2vw 5vw;
                background-color: #a1dcb0;
                position: relative;
                height: 12vw;

                p {
                    padding: 0;
                    margin: 0;
                    color: white;
                    font-size: 2.5vw;
                    letter-spacing: 0.3vw;

                }

                .text-last {
                    padding-left: 1.5vw;
                }

                .icon-next {
                    position: absolute;
                    right: 0;
                    top: 1.5vw;
                    font-size: 10vw;
                    color: white;
                }
            }
        }
    }
}

.total-prizes {
    margin: 1rem !important;
    font-weight: bolder;
    font-size: 1.25vw;
    @media (max-width: 539px) {
        font-size: 3vw;
    }   
}

.terms {
    margin-top: 2vw !important;
    background-color: #94c499;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2vw 3vw;
    color: white;
    font-size: 1.5vw;
    cursor: pointer;
    
    @media (max-width: 539px) {
        font-size: 3vw;
        margin-top: 5vw;
    }
}